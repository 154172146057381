import { useQuery } from '@apollo/client';
import loadable from '@loadable/component';
import { type FunctionComponent, useState } from 'react';
import { useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import { configureRoute } from '../../../../utils/routes/configure-route';
import { type ShowroomDetailsQuery, type ShowroomDetailsQueryVariables } from '../../../__generated__/graphql-client-types';
import { StyledButton } from '../../../components/buttons';
import { LinkButton } from '../../../components/buttons/link-button/link-button.component';
import { EmbeddedVideo } from '../../../components/common-components/embedded-video/embedded-video.component';
import { ExpandablePanel } from '../../../components/common-components/expandable-panel/expandable-panel.component';
import { Link } from '../../../components/common-components/link/link.component';
import { StyledLink } from '../../../components/common-components/link/styled-link.component';
import { Loading } from '../../../components/common-components/loading/loading.component';
import { PageContainer } from '../../../components/common-components/page/page-container/page-container.component';
import { Table, TableBody, TableCell, TableRow } from '../../../components/common-components/table/table.component';
import { CloudinaryImage } from '../../../components/images/cloudinary-image/cloudinary-image.component';
import { RedirectWithStatus } from '../../../components/redirect-w-status';
import {
	FergusonBklLogoWithViewBoxSvg,
	FergusonHomeLogoWithViewBoxSvg,
	FergusonStudioLogoWithViewBoxSvg
} from '../../../components/showroom-components/ferguson-bkl-logo-with-viewbox/ferguson-bkl-logo-with-viewbox.component';
import { ProAssociationBadge } from '../../../components/showroom-components/pro-association-badge/pro-association-badge.component';
import { ShopByLookCarousel } from '../../../components/showroom-components/shop-by-look-carousel/shop-by-look-carousel.component';
import { ShowroomAppointmentBookingModal } from '../../../components/showroom-components/showroom-appointment-booking-modal/showroom-appointment-booking-modal.component';
import { ShowroomImageCarousel } from '../../../components/showroom-components/showroom-image-carousel/showroom-image-carousel.component';
import { ShowroomMerchandisingBanner } from '../../../components/showroom-components/showroom-merchandising-banner/showroom-merchandising-banner.component';
import { SocialMediaSection } from '../../../components/social-media/social-media-section.component';
import { WarningIcon } from '../../../components/svg/icons.component';
import { FEATURE_FLAGS } from '../../../constants/general';
import { PROJECT_TOOL_LINK, SHOP_BY_LOOK_LINK, SHOWROOM_LINK, SHOWROOM_LOCATION_LINK } from '../../../constants/links';
import {
	BASE_ID,
	BASE_VIEW_ALL_URL,
	FALLS_CHURCH_STUDIO_BRANCH_PAGE_URL,
	FERGUSON_BATH_KITCHEN_LIGHTING_GALLERY_VIDEO,
	PLUMBING_LOCATION_PARAGRAPH,
	SHOP_BY_LOOK_CAROUSEL_IMAGE_DATA,
	SOCIAL_MEDIA_URLS
} from '../../../constants/showroom';
import { type CarouselItem } from '../../../helpers/carousel/carousel.helper';
import { isChromatic } from '../../../helpers/general-helper/general-helper';
import {
	displayAddress,
	displayHours,
	getFullDayName,
	getHumanReadableLocationType,
	isSupportedLocationType,
	makePlumbingLocationUrl,
	makeShowroomLocationLink
} from '../../../helpers/showroom/showroom.helper';
import { useTrackPageView } from '../../../hooks/analytics/analytics.hooks';
import { useEmployee } from '../../../hooks/apollo/employee/employee.hooks';
import { DyRecommendationContextType, useDyRecommendationContext } from '../../../hooks/dynamic-yield/dynamic-yield-client.hooks';
import { useFeature } from '../../../hooks/features/features.hooks';
import { SHOWROOM_DETAILS } from '../../../queries/showroom/showroom.queries';
import {
	type ShowroomDayHours,
	type ShowroomStoreInfo,
	type PartialShowroomAddress,
	type ShowroomNearbyBusinesses
} from '../../../types/showroom.types';
import { formatPhoneNumber } from '../../../utils/string/phone-number.utils';
import { ShowroomLocationDetail } from '../showroom-location-detail/showroom-location-detail.page';
import {
	gallery,
	locationInfoSection,
	locationSummaryBox,
	locationSummaryContainer,
	locationWarningBanner,
	prepareForAppointmentGrid,
	proAssociationGrid,
	warningBannerGap,
	warningIcon
} from './showroom-location.css';

const LoadableUpdateLocationData = loadable(
	() =>
		import(
			/* webpackChunkName: "updatelocationdata" */ '../../../components/internal-components/update-location-data/update-location-data.component'
		),
	{
		resolveComponent: ({ UpdateLocationData }) => UpdateLocationData
	}
);

type BookAppointmentSectionProps = {
	allowBookAppointment: boolean;
	onBookAppointment: () => void;
	appointmentRequired: boolean | null;
	phoneNumber: string | undefined;
	showSecondaryCta: boolean;
};

export const BookAppointmentSection: FunctionComponent<BookAppointmentSectionProps> = ({
	allowBookAppointment,
	onBookAppointment,
	appointmentRequired,
	phoneNumber,
	showSecondaryCta
}) => {
	const telURL = `tel:${phoneNumber}`;
	const showroomEnableOnlineAppointmentBooking = useFeature(FEATURE_FLAGS.SHOWROOM_ENABLE_ONLINE_APPOINTMENT_BOOKING);

	if (showroomEnableOnlineAppointmentBooking && allowBookAppointment) {
		return (
			<>
				<div className="dn dib-ns">
					<StyledButton onClick={onBookAppointment} testId="book-your-appointment">
						Book Your Appointment
					</StyledButton>
				</div>
				<div className="w-100 dn-ns">
					<StyledButton onClick={onBookAppointment} size={'LARGE'} testId="book-your-appointment">
						Book Your Appointment
					</StyledButton>
				</div>

				<p className="f7 i mt2 mb0">Appointments {appointmentRequired ? 'required' : 'recommended'}.</p>

				{showSecondaryCta && phoneNumber && (
					<div className="w-100 mt4 db dn-ns">
						<LinkButton url={telURL} buttonStyle={'SECONDARY'}>
							Call: {formatPhoneNumber(phoneNumber)}
						</LinkButton>
					</div>
				)}
			</>
		);
	}

	if (phoneNumber) {
		return (
			<>
				<div className="dn dib-ns">
					<LinkButton url={telURL} buttonStyle="PRIMARY">
						Call: {formatPhoneNumber(phoneNumber)}
					</LinkButton>
				</div>
				<div className="w-100 dn-ns">
					<LinkButton url={telURL} buttonStyle="PRIMARY" size="LARGE">
						Call: {formatPhoneNumber(phoneNumber)}
					</LinkButton>
				</div>
				<p className="f7 i mb0 mt2 b">Please call to book an appointment.</p>
				<p className="f7 i mb0 mt1">Appointments {appointmentRequired ? 'required' : 'recommended'}.</p>
			</>
		);
	}

	return null;
};

type LocationInformationSectionProps = {
	hours: ShowroomDayHours[];
	address: PartialShowroomAddress;
	storeInfo: ShowroomStoreInfo;
	nearbyBusinesses: ShowroomNearbyBusinesses[];
	isNewShowroomDetailPage: boolean;
};

export const LocationInformationSection: FunctionComponent<LocationInformationSectionProps> = ({
	hours,
	address,
	storeInfo,
	nearbyBusinesses,
	isNewShowroomDetailPage
}) => {
	const { phoneNumber, faxNumber, plumbingLocationUrl } = storeInfo;
	const { addressLine1, addressLine2, city, state, zipCode } = address;

	const address2String = addressLine2 ? `${addressLine2}, ` : '';
	const addressSearchString = `${addressLine1}, ${address2String}${city}, ${state} ${zipCode}`;
	const truncatedNearbyBusinesses = plumbingLocationUrl ? nearbyBusinesses.slice(0, 2) : nearbyBusinesses.slice(0, 7);

	return (
		<section className={`${locationInfoSection} pt5`}>
			<div>
				<p className="mb3 mt1 f4 b">Address</p>
				<div className="mb2 f5 nested-copy-line-height">
					{addressLine1 && (
						<p className="ma0" data-testid="address-street-1">
							{addressLine1}
						</p>
					)}
					{addressLine2 && (
						<p className="ma0" data-testid="address-street-2">
							{addressLine2}
						</p>
					)}
					<p className="ma0">{displayAddress({ city, state, zipCode })}</p>

					<p className="ma0">
						<StyledLink color="primary" url={`https://www.google.com/maps/place/${encodeURIComponent(addressSearchString)}`}>
							Get Directions
						</StyledLink>
					</p>
					{phoneNumber || faxNumber ? (
						<div className="mt4">
							{phoneNumber && (
								<p className="ma0" data-testid="phone-number">
									Phone:{' '}
									<StyledLink color="primary" url={`tel:${phoneNumber}`}>
										{formatPhoneNumber(phoneNumber)}
									</StyledLink>
								</p>
							)}
							{faxNumber && (
								<p className="ma0" data-testid="fax-number">
									Fax: {formatPhoneNumber(faxNumber)}
								</p>
							)}
						</div>
					) : null}
				</div>
			</div>
			<div className="dn db-ns b--theme-grey-light br" />
			{isNewShowroomDetailPage ? <div className="mv2 db dn-ns bt b--theme-grey-light" /> : null}
			<div>
				<p className="mb3 mt1 f4 b">Hours</p>
				<Table className="collapse w-100 f6 bn">
					<TableBody>
						{hours?.map((hour) => (
							<TableRow key={hour.dayName} className="bn">
								<TableCell className={`${isNewShowroomDetailPage ? 'bn' : 'bb'} b--theme-grey-light bn-ns pv2 ttc fw7`}>
									{getFullDayName(hour.dayName)}
								</TableCell>
								<TableCell className={`${isNewShowroomDetailPage ? 'bn' : 'bb'} b--theme-grey-light bn-ns pv2`}>
									{displayHours(hour.hours)}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</div>
			<div className="dn db-ns b--theme-grey-light br" />
			{isNewShowroomDetailPage && truncatedNearbyBusinesses?.length && plumbingLocationUrl ? (
				<div className="mv2 db dn-ns bt b--theme-grey-light" />
			) : null}
			<div>
				{truncatedNearbyBusinesses?.length ? (
					<div className="flex flex-column" data-testid="nearby-locations">
						<p className="mb1 mt1 f4 b">Nearby Locations</p>
						{truncatedNearbyBusinesses.map((location) => (
							<StyledLink color="primary" className="pv2" key={location.url} url={makeShowroomLocationLink(location.url)}>
								{location.name}
							</StyledLink>
						))}
					</div>
				) : null}

				{plumbingLocationUrl && (
					<div data-testid="plumbing-counter-info" className="pt3">
						<p className="mb1 f5 b">Looking for a Ferguson plumbing counter?</p>
						<p className="f6">{PLUMBING_LOCATION_PARAGRAPH}</p>
						<Link className="f6 f5-ns" url={makePlumbingLocationUrl(plumbingLocationUrl)}>
							View Ferguson Plumbing Location Details
						</Link>
					</div>
				)}
			</div>
		</section>
	);
};

type FergusonLogoSwitchProps = {
	branchPageUrl: string;
	isOmniHomeLogoSchedulerOn?: boolean;
};

export const FergusonLogoSwitch: FunctionComponent<FergusonLogoSwitchProps> = ({ branchPageUrl }) => {
	const className = 'w-100 h-100';
	return branchPageUrl === FALLS_CHURCH_STUDIO_BRANCH_PAGE_URL ? (
		<FergusonStudioLogoWithViewBoxSvg className={className} />
	) : (
		<FergusonHomeLogoWithViewBoxSvg className={className} />
	);
};

export const ShowroomLocation: FunctionComponent = () => {
	const pageName = 'landing_pages:showroom_detail_landing';
	const showroomDataLayer = {
		page: pageName,
		isShowroom: true
	};
	const { pathname } = useLocation();
	const { branchPageUrl } = useParams<{ branchPageUrl: string }>();

	const { employee } = useEmployee();
	const isInternal = employee.isAuthenticated;

	const isOmniHomeLogoSchedulerOn = useFeature(FEATURE_FLAGS.OMNI_HOME_LOGO_SCHEDULER);

	useTrackPageView({ pageName }, showroomDataLayer);

	useDyRecommendationContext({
		type: DyRecommendationContextType.OTHER_PAGE,
		data: []
	});

	const { data, loading } = useQuery<ShowroomDetailsQuery, ShowroomDetailsQueryVariables>(SHOWROOM_DETAILS, {
		variables: { id: branchPageUrl }
	});

	const [selectedBranchPageUrl, setSelectedBranchPageUrl] = useState<string | undefined>(undefined);
	const onBookAppointment = (): void => {
		setSelectedBranchPageUrl(branchPageUrl);
	};
	const onCloseBookAppointment = (): void => {
		setSelectedBranchPageUrl(undefined);
	};

	if (loading) {
		return <Loading />;
	}

	// Redirect to main showroom page if the given showroom wasn't found or it could not load.
	if (!data || !data.showroomDetails) {
		return <RedirectWithStatus path={pathname} to={SHOWROOM_LINK} />;
	}

	const {
		address,
		brands,
		branchMessage,
		headline,
		hours,
		aboutUs,
		locationImages,
		merchandisingBanners,
		nearbyBusinesses,
		products,
		proAssociations,
		services,
		storeInfo,
		locationType
	} = data.showroomDetails;

	// Redirect to main showroom page if the given showroom is not a supported business type.
	// TODO Redirect to the corresponding warehouse detail page on the Ferguson website if the location is not supported.
	if (!isSupportedLocationType(locationType)) {
		return <RedirectWithStatus path={pathname} to={SHOWROOM_LINK} />;
	}

	const { city, state, zipCode } = address || {};
	const { phoneNumber, servingText, appointmentRequired, appointmentUrl } = storeInfo || {};
	const allowBookAppointment = Boolean(appointmentUrl);

	const shopByLookCarouselItems: CarouselItem[] = SHOP_BY_LOOK_CAROUSEL_IMAGE_DATA.map((image) => ({
		description: image.value,
		id: `${BASE_ID}${image.imageId}`,
		imageType: 'private',
		url: `${BASE_VIEW_ALL_URL}${image.viewAllUrlParam || image.value}`
	}));

	const breadcrumbs = [
		{
			name: 'Showrooms',
			url: '/showroom'
		},
		{
			name: `${city}, ${state} ${getHumanReadableLocationType(locationType)}`
		}
	];

	// TODO: EFDC-3773 - Remove this line when merchandising banners are ready
	const enableMerchandisingBanners = false;

	if (isOmniHomeLogoSchedulerOn) {
		return <ShowroomLocationDetail />;
	} else {
		return (
			<PageContainer
				pageTitle={`Ferguson Kitchen & Bath Showroom in ${city}, ${state} ${zipCode}`}
				canonicalURL={pathname}
				metaDescription={`Visit your local Ferguson Bath, Kitchen & Lighting Gallery in ${city}, ${state}. Our showroom offers premium products for your next renovation or build.`}
				breadcrumbs={breadcrumbs}>
				<>
					{selectedBranchPageUrl && locationType && city && state && zipCode && !isChromatic() && (
						<div>
							<ShowroomAppointmentBookingModal
								branchPageUrl={selectedBranchPageUrl}
								locationTitle={getHumanReadableLocationType(locationType)}
								locationCity={city}
								locationState={state}
								locationZip={zipCode}
								onCancelFlow={onCloseBookAppointment}
								onFinishFlow={onCloseBookAppointment}
							/>
						</div>
					)}
					{/* TODO: SODEV-38687 - (v2) Create FixedNotificationBanner and replace this with it */}
					{branchMessage && (
						<div className={`flex pv3 ph4 br2 nl2 nr2 mh0-ns ph3 mt2 ${locationWarningBanner} ${warningBannerGap}`}>
							<WarningIcon className={`flex-none ${warningIcon}`} />
							<div>{branchMessage}</div>
						</div>
					)}
					{isInternal && <LoadableUpdateLocationData />}
					<section className="flex flex-column-reverse flex-row-ns items-center w-100 mt3">
						<div className={`w-100 w-40-l w-30-m z-4 mt2 mt0-ns relative static-ns ${locationSummaryContainer}`}>
							<div className={`${locationSummaryBox} pa3 pa5-ns br2 shadow-2 bg-theme-white`}>
								<div className="flex pb3 pb4-ns b--theme-grey-light bb">
									<div className="w-60 w-auto-ns h-auto">
										{branchPageUrl === FALLS_CHURCH_STUDIO_BRANCH_PAGE_URL ? (
											<FergusonStudioLogoWithViewBoxSvg />
										) : (
											<FergusonBklLogoWithViewBoxSvg />
										)}
									</div>
								</div>
								<div>
									<h2 className="f2 fw6 mt3 mt4-ns mb0">Ferguson {getHumanReadableLocationType(locationType)}</h2>
									<p className="mt2 ttu">{displayAddress({ city, state, zipCode })}</p>

									{servingText && (
										<p data-testid="serving-text" className="theme-grey-dark mt3 mb5">
											{servingText}
										</p>
									)}

									<div className="w-60-ns">
										<div className="tc tl-ns nowrap">
											<BookAppointmentSection
												allowBookAppointment={allowBookAppointment}
												onBookAppointment={onBookAppointment}
												appointmentRequired={appointmentRequired}
												phoneNumber={phoneNumber}
												showSecondaryCta={true}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="dn db-ns pt5 pb3"></div>
						</div>
						<div className={`${gallery} tr`}>
							{locationImages?.length ? (
								<ShowroomImageCarousel items={locationImages} ariaLabel="Showroom images" />
							) : (
								<CloudinaryImage
									className="w-100 w-auto-ns h-auto"
									publicID="mediabase/site/build.com/images/noimage"
									options={{ crop: 'lpad', width: 500, height: 500 }}
									description="No showroom images"
								/>
							)}
						</div>
					</section>

					<LocationInformationSection
						hours={hours}
						storeInfo={storeInfo}
						address={address}
						nearbyBusinesses={nearbyBusinesses}
						isNewShowroomDetailPage={false}
					/>
					<div className="mv5 b--theme-grey-light bt dn db-ns mt4" />
					<div className="flex flex-column items-center pa0 mt6 mt0-ns">
						<div className="tc-ns f6 f4-l lh-copy">
							<div className="mb4 tc-ns f6 f4-l w-80-ns lh-copy center">
								{/*  DYNAMIC CONTENT BASED ON LOCATION TYPE	*/}
								<h2 className="mv2 ma3-ns f3 f2-ns fw6 lh-solid normal ttc">{headline || 'Bring Your Vision to Us'}</h2>
								<p className="ma0 f5">{aboutUs}</p>
							</div>

							<div className={`mw7 center`}>
								<EmbeddedVideo video={FERGUSON_BATH_KITCHEN_LIGHTING_GALLERY_VIDEO} shouldLoadEagerly={isChromatic()} />
							</div>
							<div className="mv6 b--theme-grey-light bt" />
						</div>
						<div className="flex flex-wrap w-100 f5 lh-copy">
							<section className="w-70-ns pb5-ns pa0-ns pr5-ns mh0-ns">
								<h2 className="f3 f2-ns fw6 lh-title mt0 mb2">Preparing for Your Appointment</h2>
								<p className="ma0 mb2">
									Before scheduling your personalized consultation, take some time to gather the following:
								</p>
								<div className={`${prepareForAppointmentGrid}`}>
									<div>
										<h3 className="f5 f4-ns lh-title mv1">Inspiration</h3>
										What styles, colors and products are you drawn to?{' '}
										<StyledLink color="primary" url={SHOP_BY_LOOK_LINK}>
											Shop by Look{' '}
										</StyledLink>
										to view curated projects and save your favorites.
									</div>
									<div>
										<h3 className="f5 f4-ns lh-title mv1">Partners</h3>
										Let us know if you’re tackling this project on your own or if you’ve already started working with
										other professionals.
									</div>
									<div>
										<h3 className="f5 f4-ns lh-title mv1">Details</h3>
										Collect plans, blueprints, measurements and more so we can help you pick out the perfect products.
									</div>
									<div>
										<h3 className="f5 f4-ns lh-title mv1">Budget</h3>
										To make sure we stay on track, it’s important to understand your established budget, goals and
										expectations.
									</div>
								</div>
								<div className="mt5 tc tl-ns">
									<BookAppointmentSection
										allowBookAppointment={allowBookAppointment}
										onBookAppointment={onBookAppointment}
										appointmentRequired={appointmentRequired}
										phoneNumber={phoneNumber}
										showSecondaryCta={false}
									/>
								</div>

								{/* PRODUCTS | SERVICES | BRANDS */}
								<h2 className="f3 f2-ns fw6 lh-title mt0 mb3 pt6">At This Location</h2>
								{products?.length ? (
									<ExpandablePanel heading={'Products'} className="f5 mb3">
										<ul className="flex flex-wrap w-100 list">
											{products.map((product, index) => (
												<li key={`product-${index}`} className="w-100 w-33-ns ma0">
													{product}
												</li>
											))}
										</ul>
									</ExpandablePanel>
								) : null}
								{services?.length ? (
									<ExpandablePanel heading={'Services'} className="f5 mb3">
										<ul className="flex flex-wrap w-100 list">
											{services.map((service, index) => (
												<li key={`service-${index}`} className="w-100 w-33-ns ma0">
													{service}
												</li>
											))}
										</ul>
									</ExpandablePanel>
								) : null}
								{brands?.length ? (
									<ExpandablePanel heading={'Brands'} className="f5">
										<ul className="flex flex-wrap w-100 list">
											{brands.map((brand, index) => (
												<li key={`brand-${index}`} className="w-100 w-33-ns ma0">
													{brand}
												</li>
											))}
										</ul>
									</ExpandablePanel>
								) : null}

								{/* EVENTS AT THIS SHOWROOM */}
								{/* TODO: SODEV-39194: Implement this with event data */}
								{/* <h2 className="f3 f2-ns fw3 mb3">Events at this Showroom</h2>

								<div className="flex bg-theme-grey-lighter br2 mb3">
									<div className="bg-theme-error br2 br--left theme-white tc">THIS WILL BE AN IMAGE</div>
									<div className="mv3 ml5">
										<div className="flex flex-column theme-grey">
											<p className="f6 ma0">SHORTHAND MONTH</p>
											<p className="f5 ma0">DATE</p>
										</div>

										<div className="flex flex-column">
											<h3 className="f3 mt2 mb1">Live Kitchen Demonstration</h3>
											<p className="ma0 f7 theme-grey-darker">5:30 PM - 8:00 PM</p>
										</div>
									</div>
								</div>
								<div className="flex bg-theme-grey-lighter br2">
									<div className="bg-theme-error br2 br--left theme-white tc">THIS WILL BE AN IMAGE</div>
									<div className="mv3 ml5">
										<div className="flex flex-column theme-grey">
											<p className="f6 ma0">SHORTHAND MONTH</p>
											<p className="f5 ma0">DATE</p>
										</div>

										<div className="flex flex-column">
											<h3 className="f3 mt2 mb1">Live Kitchen Demonstration</h3>
											<p className="ma0 f7 theme-grey-darker">5:30 PM - 8:00 PM</p>
										</div>
									</div>
								</div> */}

								{/* PROFESSIONAL ASSOCIATIONS */}
								{proAssociations?.length ? (
									<>
										<h2 className="f3 f2-ns fw6 mt0 mb3 pt6" data-testid="pro-associations">
											Professional Associations & Memberships
										</h2>
										<div className={`${proAssociationGrid}`}>
											{proAssociations.map((association, index) => (
												<ProAssociationBadge key={`pro-association-${index}`} {...association} />
											))}
										</div>
									</>
								) : null}
							</section>

							{/* SIDEBAR */}
							<section className="w-100 w-30-ns flex flex-column mt2-ns">
								<div className="pa4 ba b--theme-grey-light br2 mt6 mt0-ns flex flex-column">
									<CloudinaryImage
										publicID="content-tool/thckqi0bvjehimxrpi4q"
										options={{ width: 250, height: undefined }}
										description=""
										className="self-center"
									/>

									<h3 className="f3 lh-title mb0">Need help getting organized?</h3>
									<div>
										Use our free Build with Ferguson <Link url={PROJECT_TOOL_LINK}>Project Tool</Link> to save your
										favorite products and organize them by project or room.
									</div>

									{/* TODO: SODEV-43035 / EFDC-3773 - Re-enable merchandising banners when ready */}
									{enableMerchandisingBanners ? (
										<div className="flex flex-column mt4">
											{merchandisingBanners.map((banner, index) => (
												<ShowroomMerchandisingBanner key={index} {...banner} />
											))}
										</div>
									) : null}

									<div className="w-100 mv5 b--theme-grey-light bt" />

									<SocialMediaSection
										urls={SOCIAL_MEDIA_URLS}
										useDivider={false}
										className="w-100-ns pa0"
										header="Follow Ferguson Showrooms"
									/>
								</div>
							</section>
						</div>

						{/* CAROUSELS */}
						<div className="w-100 mt4">
							<section id="dy-recs-showroom-location-page-1" data-testid="DY Placement"></section>
						</div>

						<div className="mt4">
							<ShopByLookCarousel items={shopByLookCarouselItems} />
						</div>
					</div>
				</>
			</PageContainer>
		);
	}
};

export const RoutedShowroomLocation = configureRoute(
	{ path: SHOWROOM_LOCATION_LINK, exact: false, DANGEROUSLY_IS_LIVE: true },
	ShowroomLocation
);
